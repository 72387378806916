import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { STEPS_STORE } from "../constants/constant";
import { StepTypeEnum, StepsType } from "src/types";
import { TFunction } from "i18next";

const initialStepsState = {
  steps: [],
  currentId: "insurance",
  isPageLoading: false,
};

export const useStepStore = create(
  devtools(
    persist<StepsType>(
      (set) => ({
        ...initialStepsState,
        setSteps: (type: StepTypeEnum, t: TFunction) => {
          try {
            set(() => ({
              steps: assignStepsByStepType(type, t),
            }));
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setCurrentStepId: (id: string) => {
          try {
            set(() => ({
              currentId: id,
            }));
          } catch (err: unknown) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setIsPageLoading: (value: boolean) => {
          try {
            set(() => ({
              isPageLoading: value,
            }));
          } catch (err: unknown) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        clearSteps: () => {
          set(() => initialStepsState);
        },
      }),
      {
        name: STEPS_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);

const assignStepsByStepType = (type: StepTypeEnum, t: TFunction) => {
  switch (type) {
    case StepTypeEnum.AOV:
      return [
        {
          id: "insurance",
          title: t("header.steps.insurances"),
          widthPercentage: "25%",
          mobileWidthPercentage: "25%",
        },
        {
          id: "knowledgeTest",
          title: t("header.steps.knowledgeTest"),
          widthPercentage: "25%",
          mobileWidthPercentage: "25%",
        },
        {
          id: "medicalHistory",
          title: t("header.steps.medicalHistory"),
          widthPercentage: "25%",
          mobileWidthPercentage: "25%",
        },
        {
          id: "checkout",
          title: t("header.steps.checkout"),
          widthPercentage: "25%",
          mobileWidthPercentage: "25%",
        },
      ];
    case StepTypeEnum.AOV_ACTURE:
      return [
        {
          id: "insurance",
          title: t("header.steps.insurances"),
          widthPercentage: "33%",
          mobileWidthPercentage: "33%",
        },
        {
          id: "medicalHistory",
          title: t("header.steps.medicalHistory"),
          widthPercentage: "33%",
          mobileWidthPercentage: "33%",
        },
        {
          id: "checkout",
          title: t("header.steps.checkout"),
          widthPercentage: "33%",
          mobileWidthPercentage: "33%",
        },
      ];
    case StepTypeEnum.CALCULATOR:
      return [
        {
          id: "insurance",
          title: t("header.steps.insurances"),
          widthPercentage: "30%",
          mobileWidthPercentage: "25%",
        },
        {
          id: "calculate",
          title: t("header.steps.calculate"),
          widthPercentage: "50%",
          mobileWidthPercentage: "50%",
        },
        {
          id: "result",
          title: t("header.steps.result"),
          widthPercentage: "20%",
          mobileWidthPercentage: "25%",
        },
      ];
    default:
      return [
        {
          id: "insurance",
          title: t("header.steps.insurances"),
          widthPercentage: "30%",
          mobileWidthPercentage: "25%",
        },
        {
          id: "personalise",
          title: t("header.steps.personalise"),
          widthPercentage: "50%",
          mobileWidthPercentage: "50%",
        },
        {
          id: "checkout",
          title: t("header.steps.checkout"),
          widthPercentage: "20%",
          mobileWidthPercentage: "25%",
        },
      ];
  }
};
